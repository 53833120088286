*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}


html{
    font-size: 62.5%;
}

.contenedorLogin{
    background-color: #354259;
    margin: 0;
    padding: 0;
    background-color: #354259;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Asegura que el contenedor ocupe al menos el 100% de la altura de la ventana */

}

.login-container {
    max-width: 400px;
    min-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 16px;
    background: #354259;
    box-shadow:  35px 35px 70px #2d384c,
                 -35px -35px 70px #3d4c66;
}

.h1-login {
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
}


.logo {
    text-align: center;
    margin-bottom: 20px;
}

.logo img {
    max-width: 150px;
    height: auto;
}

/* Estilos para los campos del formulario */
.form-group {
    margin-bottom: 20px;
}

.label-login {
    display: block;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 5px;
}


.inputs-login{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}

.select-login {
    background-size: 20px;
    padding-right: 30px;
}

.button-login {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}


.mensaje{
    font-size: 1.5rem;
    width: auto;
    margin-top: 20px;
    height: 3.2rem;
    text-align: center;
}

/* Estilos responsivos */
@media screen and (max-width: 600px) {
    .login-container {
        max-width: 100%;
    }
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
    font-family: 'PT Serif', serif;
}

h1{
    font-size: 3rem;
}
h2{
    font-size: 2.4rem;
}

label{
    font-size: 1rem;
}

.principal{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.secundario{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
}

.botones{
    height: auto;
    width: 40vw;
    font-size: 2.4rem;
    border-radius: 15px;
}

.contenedorTicket{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'PT Serif', serif;
}
.contenedoresTicket{
    width: 35rem;
}

.cambiarFactura{
    position: relative;
    font-size: 2.4rem ;
    margin-top: 2rem;
}

.ContenedorGuardarPago{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}



.btn-outline-secondary:hover{
    color: white;
    background-color: #6c757d;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btnborder-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
    background-color: #0d6efd;
    color: #fff;
    width: 35rem;
    border-radius: 40px;
    display: inline-block;
    padding: var(-btn-padding-y) var(-btn-padding-x);
    font-family: var(btn-font-family);
    font-weight: 0.1;
    line-height: 1.5;
    color: var(color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: 10px;
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 2.4rem;
}

.btn-primary2 {
    width: 5.5rem;
    color: #fff;
    background-color: #0b5ed7;
    border-radius: 10px;
}

.btn-primary2:hover {
    color: #fff;
    background-color: #0a58ca;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0a58ca;
}

.btn-outline-secondary {
    --bs-btn-color: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-focus-shadow-rgb: 108,117,125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6c757d;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    width: 35rem;
    color: #6c757d;
    background-color: transparent;
    font: 23px "PT Serif";
    margin: 2.5px;
    padding: 7.5px 7.5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border-color: #6c757d;
}


.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-radius: 10px;
    border-color: #ffc107;
    padding: 7px 7.5px;
}

.btn-warning2 {
    color: #000;
    background-color: #0d6efd;
    border-radius: 10px;
    border-color: #0d6efd;
    padding: 7px 7.5px;
    color: white;
}


.btn-warning3 {
    color: #000;
    background-color: #ffc107;
    border-radius: 10px;
    border-color: #ffc107;
    padding: 7px 7.5px;
}


.btn-warning4 {
    color: #000;
    background-color: #f8d7da;
    border-radius: 10px;
    border-color: #f8d7da;
    padding: 7px 7.5px;
}



.btn-warning:hover{
    color: #000;
    background-color: gold;
}

.ContenedorLogin{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 7rem;
    grid-gap: 7rem;
    gap: 7rem;
    background-color: #354259;
    color: #ffffff;

}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: 0px;
}



/*.btn {
    display: flex;
    flex-direction: row;
}*/

.contenedorFacturacion {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'PT Serif', serif;
}

.botonConsultarTicket {
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #9ce3a8;
    border-color: #9ce3a8;
    border-radius: 10px;
    padding: 4px 7.5px;
}

.botonCrearCierre {
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #FFF3CD;
    border-color: #FFF3CD;
    color:#664D03;
    border-radius: 10px;
    padding: 4px 7.5px;
}


.botonConsultarTicket:hover {
    color: #000;
    background-color: #fff;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    width: 20%;
    align-self: center;
}

.d-grid {
    display: grid!important;
}

.gap-2 {
    grid-gap: 0.5rem!important;
    gap: 0.5rem!important;
}

input[type="radio" i] {
    background-color: initial;
    cursor: default;
    -webkit-appearance: auto;
            appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 0px 5px;
    padding: initial;
    border: initial;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

/*.btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent;
}*/

.btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-outline-primary {
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    padding: 10px;
    border: 2px solid #0d6efd;
    white-space: nowrap;
    
}

.offset-md-3 {
    margin-left: 25%;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.text-span {
    height: 36.5px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    margin-right: 0px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-text {
    position: relative;
    width: 20%;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: wrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    align-content: center;
}

.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 20%;
    min-width: 0;
    white-space: wrap;
}

.form-label {
    margin-bottom: 0.5rem;
    font: 26px system-ui;
}

.m-3 {
    margin: 1rem!important;
}

.contenedorP {
    height: 100vh;
}

.container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.imagen{
    width: 25rem;
    height: auto;
}

.cambiarTicket{
    position: absolute;
    font-size: 2.4rem ;
    top:66rem;
    left: 47.5vw;
}


.contenedorServicio{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

label{
    font-size: 2.4rem;
    width: 25rem;
    text-align: start;
}

.sBotones{
    font-size: 2.5rem;
}

.generar{
    margin-top: 1rem;
    font-size: 2.1rem;
}

.p{
    width: 35rem;
    text-align: justify;
    font-size: 1.5rem;
}

.p2{
    margin-top: 1rem;
    width: 25rem;
    text-align: center;
    font-size: 1.4rem;
}


.inputCasco{
    width: 35rem;
    font-size: 2rem;
}

.contenedorFacturacion{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
    flex-direction: column;
    font-family: 'PT Serif', serif;
}

.botonConsultarTicket{
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #9ce3a8;

}
.ContenedorMostrarF{
    background-color: white;
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'PT Serif', serif;
}

.contenedorDatosFactura{
    display: flex;
    flex-direction: column;
    font-family: 'PT Serif', serif;
}



.labelDatosFactura{
    width: auto;
    font-size: 2.2rem;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-weight: bold;
}

.labelDatosFacturac{
    margin-top: 0px;
    width: auto;
    font-size: 1.6rem;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-weight: bold;
}

.contendorCaculo{
    width: 100vw;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;

}

.labelDatosFacturacalculo{
    width: 40rem;
    font-size: 2rem;
    font-family: 'PT Serif', serif;
}

.selectCalcula{
    font-size: 2rem;
    width: 18rem;
}

.inputCacular{
    width: 26rem;
    font-size: 2rem;
}

.contenedorCabecera{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.ConternedorbotonesTipoCliente{
    width: 40rem;
}



.contenedorInfoF{
    display: flex;
    flex-direction: column;
    width: 40rem;

}



.imagen2{
    background-color: white;
    width: 10rem;
    height: auto;
    margin-top: 23px;
    position: relative;
    top: -37.3rem;
}

.inputF{
    width: 22rem;
    font-size: 2rem;
}

.selectF{
    font-size: 2rem;
    height: 3.4rem;
    background-color: #ece5c7;
}

.enviarF{
    font-size: 2.4rem;
}

.contenedorInfoFactura{
    display: flex;
    flex-direction: column;
    width: 35rem;
    margin-top: 1rem;
    font-weight: bold;
    font-family: 'PT Serif', serif;
    }

.contenedor-crearticket {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.contenedor-crearticket-grupo {
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-top: 1rem;
}

.contenedor-crearticket-grupo1 {
    display: flex;
    width: 50vw;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contenedor-crearticket-grupo2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.input-crearticket {
    width: 50vw;
    font-size: 2rem;
    height: 4rem;
}

.input-crearticket2 {
    width: 22vw;
    font-size: 2rem;
    height: 4rem;
}

.contenedorMensualidadPrincipal {
    display: grid;
    width: 50vw;
    width: auto;
    grid-template-columns: 25vw 25vw;
    grid-template-rows: auto;
    grid-template-areas: "contenedor1 contenedor2"
    "contenedor3 contenedor4";
}

.contenedor1 {
    grid-area: contenedor1;
}

.contenedor2 {
    grid-area: contenedor2;
}

.contenedor3 {
    grid-area: contenedor3;
}

.contenedor4 {
    grid-area: contenedor4;
}

    .alert {
     
        color: var(--bs-alert-color);
        background-color: var(--bs-alert-bg);

    }
    .alert-success {
        --bs-alert-color: #0f5132;
        --bs-alert-bg: #d1e7dd;
        --bs-alert-border-color: #badbcc;
    }

    .alert-primary {
        --bs-alert-color: #084298;
        --bs-alert-bg: #cfe2ff;
        --bs-alert-border-color: #b6d4fe;
    }
    .alert-warning {
        --bs-alert-color: #664d03;
        --bs-alert-bg: #fff3cd;
        --bs-alert-border-color: #ffecb5;
    }
    .alert-danger {
        --bs-alert-color: #842029;
        --bs-alert-bg: #f8d7da;
        --bs-alert-border-color: #f5c2c7;
    }


.marca{
   position: absolute;
    top:45rem;
    opacity:0.8;
    color:red;
    font-size: 5rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.ContenedorQRFactura{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

video{
    width: 50vw;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.button-Activar-Camara{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 1024px) {

    h2{
        font-size: 2.6rem;
    }
    
    label{
        font-size: 2.6rem;
    }

    
.contenedoresTicket{
    width: 48rem;
}

.p{
    width: 48rem;
}

.p2{
    width: 48rem;
}
.inputCasco{
    width: 48rem;
    font-size: 2rem;
}
    
}


.input-TipoPago{
    font-size: 2.4rem;
}

.select-TipoPago{
 margin-bottom: 1rem;
}
.contenedorPirncipalReportes{
    display: flex;
    width: 100vw;
    height: auto;
    flex-wrap:wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    background-color: #00719f;
    
}

.contenedor-Modal{
width: 35vw;
height: 40vh;
display: flex;
flex-direction: column;
align-items: center;
background-color:#DCDCDC;
position: absolute;
top: 30vh;
left: 32vw;
border-radius: 15px;
border: solid 2px #DCDCDC;
font-size: 2.4rem;
font-family:'PT Serif', serif;
}

.Contenedor-Reportes-Menu{
background-color: #00719f;
width: 100vw;
height: auto;
}

.contenedor-boton-menu{
  position: relative;
  top:0vh;
  left: 90vw;
  padding-bottom: 10px;
}

.contenedor-Reporte-NumeroFactura{
position: absolute;
left: 40vw;
top:130vh;
color: white;
}

.contenedor-Formulario-Modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.contenedor-Formulario-Modal-Filtro{
  display: flex;
}
.contenedor-Botones-Modal{
    margin-top: 1rem;
    display: flex;
    width: 20vw;
    justify-content: space-evenly;   
}

.btn-Reporte-Modal{
    margin-top: 2rem;
}

.input-Modal{
    font-size: 2rem;
    width: 250px;
}

.seccionReporte{
width: 22vw;
height: 41vh;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 24px;
background: #003e5d;
box-shadow:  24px 24px 40px #002f46,;
}


.contenedor1-seccionReporte{
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35vh;
}

.contenedor2-seccionReporte{
display: flex;
justify-content: space-evenly;
width: 32rem;
}

.icono{
   width: 50px;
   height: 50px;
    object-fit: cover;
}

.btnReporte2{
    margin-bottom:2rem;
    padding:1rem;
    border-radius:10px;
    border-color:solid 1px #b6effb;
    background-color: #cff4fc;
    color: #055160
}

.contenedor-Pricipal-Modal{
    margin-top: 0px;
    text-align: center;
}

.btnReporteConsultar{
    margin-bottom:2rem;
    padding:1rem;
    border-radius:10px;
    border-color:solid 1px #badbcc;
    background-color:#d1e7dd;
    color:#0f5132
}


.botonActualizar{
    padding: 10px;
    border-radius: 10px;
    background-color: #cfe2ff;
    color: white;
    border: solid 1px #b6d4fe;
}


.botonExportar{
    padding: 10px;
    border-radius: 10px;
    background-color: #d1e7dd;
    color: #0f5132;
    border: solid 1px #badbcc;
    font-size: 1.6rem;
}


.botonActualizar{
    padding: 10px;
    border-radius: 10px;
    background-color: #e2e3e5;
    color: #41464b;
    border: solid 1px #d3d6d8;
    font-size: 1.6rem;
}

.botonRegresar{
    padding: 10px;
    border-radius: 10px;
    background-color: #f8d7da;
    color: #842029;
    border: solid 1px #f5c2c7;
    font-size: 1.6rem;
}


.loader,
.loader:before,
.loader:after {
  background: #09F;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
}
.loader {
  text-indent: -9999em;
  margin: 40% auto;
  position: relative;
  font-size: 11px;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.loader:after {
  left: 1.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #FFF;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #ffffff;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #09F;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #09F;
    height: 5em;
  }
}

@media (max-width: 720px) {

  .seccionReporte{
    width: 90vw;
  }

  .contenedorPirncipalReportes{
    height: 100%;
  }

  .Contenedor-Reportes-Menu{
    position: static;
    margin-left: 75vw;
    margin-top: -15px;
  }

  .contenedor-Reporte-NumeroFactura{
    position: static;
    margin-top:-50px;
    color: black;
    }

    .contenedor-Modal{
      width: 80vw;
      height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color:#DCDCDC;
      position: absolute;
      top: 25vh;
      left: 10vw;
      border-radius: 15px;
      border: solid 2px #DCDCDC;
      font-size: 2.4rem;
      font-family:'PT Serif', serif;
      }
}





.ContenedorFiltro{
    width: 100vw;
    height: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.filtroinput{
    font-size: 2rem;
}

.selectfiltro{
    font-size: 2rem;
}

.botonFiltro{
    margin-top: 2rem;
    padding: 10px;
    color:#084298 ;
    background-color:#cfe2ff;
    border: solid 1px #b6d4fe;
    border-radius: 10px;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
}

.contenedorReportes{
    width: 100vw;
    height: 90vh;
    display: flex;
    background-color: white;
    justify-content: space-around;
}

.contenedorReportes1{
    display: flex;
    flex-direction: column;
    width: 40vw;
}

.contenedorReportes2{
  
    display: flex;
    flex-direction: column;
    width: 40vw;
}

.contenedorReportes3{
    display:flex;
    width: 100vw;
    background-color: white;
    justify-content: center;
}

.contenedorReporteUsuario{
    width: 40vw;
    margin-top: 2rem;
}

.contenedorReporteFecha{
    width: 40vw;
    margin-top: 2rem;
}

.tabla{
    border-collapse: collapse; margin: 25px 0; font-size: 1.4rem;  min-width: 450px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
}

thead tr{
    background-color: #4682B4; color: #ffffff; text-align: middle;
}

td,th{
    padding: 12px 15px; 
}

tbody tr {
    border-bottom: 1px solid #dddddd;
}

 tbody tr:nth-of-type(even) { background-color: #f3f3f3; }

 tbody tr:last-of-type { border-bottom: 2px solid #009879; }
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
}


.contenedorP{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    background-color: white
}

.seccion-Botones{
    margin-top: 1rem;
    display: flex;
    height: 10vh;
    width: 100vw;
    justify-content: center;
    flex-wrap: wrap;
}

.seccion-Botones-Boton{
    padding: 5px;
    font-size: 1.8rem;
    border: solid 2px #0d6efd;
    width: 25vw;
    text-align: center;
}



.lista{
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    display: flex;
    padding: 10px;
  justify-content: center;
}

.contenedorLista{

  margin-top: 1rem;
}


.contenedorLista label{
    width: auto;
    font-size: 2rem;
}


.ContenedorTicketE{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'PT Serif', serif;
    justify-content: center;
    background-color: white;
}



.col-md-3 input{
    margin-left: 1rem;
}

.col-md-3 label{
    width: auto;
}

.ContenedorPago{
    width: 22vw;
    display: flex;
    align-items: start;
}


#contenedorDias input{
    margin-left: 0px;
    font-size: 1.8rem;
}




#contendoresSpan span{
    font-size: 2rem;
}

.cambiarOtro{
    position: relative;
    top:35rem;
    left: 30rem;
    font-size: 2rem;
}

.justify-content-md-center {
    justify-content: center!important;
}

.mt-3 {
    margin-top: 1rem!important;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.mb-3 {
    margin-bottom: 1rem!important;
}

.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}

.offset-md-3 {
    margin-left: 25%;
}

.offset-md-4 {
    margin-left: 0%;
}

.offset-md-5 {
    margin-left: 10%;
    margin-top: 1rem;
}

.mt-5 {
    margin-top: 1rem;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    width: 20%;
    align-self: center;
    margin: 31%;
}

.gap-2 {
    grid-gap: 0.5rem!important;
    gap: 0.5rem!important;
}

.d-grid {
    display: grid!important;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.btn-verde {
    width: 40vw;
    color: #fff;
    background-color: #146c43;
    padding: 1rem;
    border-color: #146c43;
    border-radius: 10px;
}

.btn-verde:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}




@media (max-width: 1024px) {



}


*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
}

.contendorConsultarFactura{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.span{
    font-size: 2rem;
}

.input{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.375rem;
    font-size: 2rem;
}

.boton{
    margin-top: 1rem;
    font-size: 2rem;
    background-color: #9ce3a8;
    border: none;
    padding: 0.5rem;
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-header {
  padding: 2px 16px;
  background-color: #fefefe;
  color: black;
}

.modal-body {
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #fefefe;
  display: flex;
  justify-content: space-between;
}


.contenedor{
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}


.contenedorGrupo{
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.elementLabel{
  font-size: 18px;
  margin-bottom: 2px;
}

.elemetInputCaj{
  font-size: 14px;
  padding: 5px;
  width: 15vw;
  border: 0.5px solid black;
  border-radius: 5px;
}

.elemetInputCaj2{
  font-size: 14px;
  padding: 5px;
  width: 30vw;
  border: 0.5px solid black;
  border-radius: 5px;
}

.danger{
    background-color: #f8d7da;
    color: #842029;
    border: solid 1px #f5c2c7;
}

.agregarBotonCaj{
  background-color: #D1E7DD;
  color: #0A3622;
  border: solid 1px #a3cfbb;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}

.importarBotonCaj{
  background-color: #CFE2FF;
  color: #052C65;
  border: solid 1px #9ec5fe;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}

.exportBotonCaj{
  background-color: #FFF3CD;
  color: #664D03;
  border: solid 1px #ffe69c;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}



