
.ContenedorFiltro{
    width: 100vw;
    height: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.filtroinput{
    font-size: 2rem;
}

.selectfiltro{
    font-size: 2rem;
}

.botonFiltro{
    margin-top: 2rem;
    padding: 10px;
    color:#084298 ;
    background-color:#cfe2ff;
    border: solid 1px #b6d4fe;
    border-radius: 10px;
}
