*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}


html{
    font-size: 62.5%;
}

.contenedorLogin{
    background-color: #354259;
    margin: 0;
    padding: 0;
    background-color: #354259;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Asegura que el contenedor ocupe al menos el 100% de la altura de la ventana */

}

.login-container {
    max-width: 400px;
    min-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 16px;
    background: #354259;
    box-shadow:  35px 35px 70px #2d384c,
                 -35px -35px 70px #3d4c66;
}

.h1-login {
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
}


.logo {
    text-align: center;
    margin-bottom: 20px;
}

.logo img {
    max-width: 150px;
    height: auto;
}

/* Estilos para los campos del formulario */
.form-group {
    margin-bottom: 20px;
}

.label-login {
    display: block;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 5px;
}


.inputs-login{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}

.select-login {
    background-size: 20px;
    padding-right: 30px;
}

.button-login {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}


.mensaje{
    font-size: 1.5rem;
    width: auto;
    margin-top: 20px;
    height: 3.2rem;
    text-align: center;
}

/* Estilos responsivos */
@media screen and (max-width: 600px) {
    .login-container {
        max-width: 100%;
    }
}