.contenedorPirncipalReportes{
    display: flex;
    width: 100vw;
    height: auto;
    flex-wrap:wrap;
    gap: 20px;
    justify-content: center;
    background-color: #00719f;
    
}

.contenedor-Modal{
width: 35vw;
height: 40vh;
display: flex;
flex-direction: column;
align-items: center;
background-color:#DCDCDC;
position: absolute;
top: 30vh;
left: 32vw;
border-radius: 15px;
border: solid 2px #DCDCDC;
font-size: 2.4rem;
font-family:'PT Serif', serif;
}

.Contenedor-Reportes-Menu{
background-color: #00719f;
width: 100vw;
height: auto;
}

.contenedor-boton-menu{
  position: relative;
  top:0vh;
  left: 90vw;
  padding-bottom: 10px;
}

.contenedor-Reporte-NumeroFactura{
position: absolute;
left: 40vw;
top:130vh;
color: white;
}

.contenedor-Formulario-Modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.contenedor-Formulario-Modal-Filtro{
  display: flex;
}
.contenedor-Botones-Modal{
    margin-top: 1rem;
    display: flex;
    width: 20vw;
    justify-content: space-evenly;   
}

.btn-Reporte-Modal{
    margin-top: 2rem;
}

.input-Modal{
    font-size: 2rem;
    width: 250px;
}

.seccionReporte{
width: 22vw;
height: 41vh;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 24px;
background: #003e5d;
box-shadow:  24px 24px 40px #002f46,;
}


.contenedor1-seccionReporte{
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35vh;
}

.contenedor2-seccionReporte{
display: flex;
justify-content: space-evenly;
width: 32rem;
}

.icono{
   width: 50px;
   height: 50px;
    object-fit: cover;
}

.btnReporte2{
    margin-bottom:2rem;
    padding:1rem;
    border-radius:10px;
    border-color:solid 1px #b6effb;
    background-color: #cff4fc;
    color: #055160
}

.contenedor-Pricipal-Modal{
    margin-top: 0px;
    text-align: center;
}

.btnReporteConsultar{
    margin-bottom:2rem;
    padding:1rem;
    border-radius:10px;
    border-color:solid 1px #badbcc;
    background-color:#d1e7dd;
    color:#0f5132
}


.botonActualizar{
    padding: 10px;
    border-radius: 10px;
    background-color: #cfe2ff;
    color: white;
    border: solid 1px #b6d4fe;
}


.botonExportar{
    padding: 10px;
    border-radius: 10px;
    background-color: #d1e7dd;
    color: #0f5132;
    border: solid 1px #badbcc;
    font-size: 1.6rem;
}


.botonActualizar{
    padding: 10px;
    border-radius: 10px;
    background-color: #e2e3e5;
    color: #41464b;
    border: solid 1px #d3d6d8;
    font-size: 1.6rem;
}

.botonRegresar{
    padding: 10px;
    border-radius: 10px;
    background-color: #f8d7da;
    color: #842029;
    border: solid 1px #f5c2c7;
    font-size: 1.6rem;
}


.loader,
.loader:before,
.loader:after {
  background: #09F;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
}
.loader {
  text-indent: -9999em;
  margin: 40% auto;
  position: relative;
  font-size: 11px;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.loader:after {
  left: 1.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #FFF;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #ffffff;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #09F;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #09F;
    height: 5em;
  }
}

@media (max-width: 720px) {

  .seccionReporte{
    width: 90vw;
  }

  .contenedorPirncipalReportes{
    height: 100%;
  }

  .Contenedor-Reportes-Menu{
    position: static;
    margin-left: 75vw;
    margin-top: -15px;
  }

  .contenedor-Reporte-NumeroFactura{
    position: static;
    margin-top:-50px;
    color: black;
    }

    .contenedor-Modal{
      width: 80vw;
      height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color:#DCDCDC;
      position: absolute;
      top: 25vh;
      left: 10vw;
      border-radius: 15px;
      border: solid 2px #DCDCDC;
      font-size: 2.4rem;
      font-family:'PT Serif', serif;
      }
}



