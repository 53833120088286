*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
}

.contenedorReportes{
    width: 100vw;
    height: 90vh;
    display: flex;
    background-color: white;
    justify-content: space-around;
}

.contenedorReportes1{
    display: flex;
    flex-direction: column;
    width: 40vw;
}

.contenedorReportes2{
  
    display: flex;
    flex-direction: column;
    width: 40vw;
}

.contenedorReportes3{
    display:flex;
    width: 100vw;
    background-color: white;
    justify-content: center;
}

.contenedorReporteUsuario{
    width: 40vw;
    margin-top: 2rem;
}

.contenedorReporteFecha{
    width: 40vw;
    margin-top: 2rem;
}

.tabla{
    border-collapse: collapse; margin: 25px 0; font-size: 1.4rem;  min-width: 450px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
}

thead tr{
    background-color: #4682B4; color: #ffffff; text-align: middle;
}

td,th{
    padding: 12px 15px; 
}

tbody tr {
    border-bottom: 1px solid #dddddd;
}

 tbody tr:nth-of-type(even) { background-color: #f3f3f3; }

 tbody tr:last-of-type { border-bottom: 2px solid #009879; }