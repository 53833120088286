*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
    font-family: 'PT Serif', serif;
}

h1{
    font-size: 3rem;
}
h2{
    font-size: 2.4rem;
}

label{
    font-size: 1rem;
}

.principal{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.secundario{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}

.botones{
    height: auto;
    width: 40vw;
    font-size: 2.4rem;
    border-radius: 15px;
}

.contenedorTicket{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'PT Serif', serif;
}
.contenedoresTicket{
    width: 35rem;
}

.cambiarFactura{
    position: relative;
    font-size: 2.4rem ;
    margin-top: 2rem;
}

.ContenedorGuardarPago{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}



.btn-outline-secondary:hover{
    color: white;
    background-color: #6c757d;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btnborder-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
    background-color: #0d6efd;
    color: #fff;
    width: 35rem;
    border-radius: 40px;
    display: inline-block;
    padding: var(-btn-padding-y) var(-btn-padding-x);
    font-family: var(btn-font-family);
    font-weight: 0.1;
    line-height: 1.5;
    color: var(color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: 10px;
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 2.4rem;
}

.btn-primary2 {
    width: 5.5rem;
    color: #fff;
    background-color: #0b5ed7;
    border-radius: 10px;
}

.btn-primary2:hover {
    color: #fff;
    background-color: #0a58ca;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0a58ca;
}

.btn-outline-secondary {
    --bs-btn-color: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-focus-shadow-rgb: 108,117,125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6c757d;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    width: 35rem;
    color: #6c757d;
    background-color: transparent;
    font: 23px "PT Serif";
    margin: 2.5px;
    padding: 7.5px 7.5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border-color: #6c757d;
}


.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-radius: 10px;
    border-color: #ffc107;
    padding: 7px 7.5px;
}

.btn-warning2 {
    color: #000;
    background-color: #0d6efd;
    border-radius: 10px;
    border-color: #0d6efd;
    padding: 7px 7.5px;
    color: white;
}


.btn-warning3 {
    color: #000;
    background-color: #ffc107;
    border-radius: 10px;
    border-color: #ffc107;
    padding: 7px 7.5px;
}


.btn-warning4 {
    color: #000;
    background-color: #f8d7da;
    border-radius: 10px;
    border-color: #f8d7da;
    padding: 7px 7.5px;
}



.btn-warning:hover{
    color: #000;
    background-color: gold;
}

.ContenedorLogin{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 7rem;
    gap: 7rem;
    background-color: #354259;
    color: #ffffff;

}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: 0px;
}



/*.btn {
    display: flex;
    flex-direction: row;
}*/

.contenedorFacturacion {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'PT Serif', serif;
}

.botonConsultarTicket {
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #9ce3a8;
    border-color: #9ce3a8;
    border-radius: 10px;
    padding: 4px 7.5px;
}

.botonCrearCierre {
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #FFF3CD;
    border-color: #FFF3CD;
    color:#664D03;
    border-radius: 10px;
    padding: 4px 7.5px;
}


.botonConsultarTicket:hover {
    color: #000;
    background-color: #fff;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    width: 20%;
    align-self: center;
}

.d-grid {
    display: grid!important;
}

.gap-2 {
    gap: 0.5rem!important;
}

input[type="radio" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 0px 5px;
    padding: initial;
    border: initial;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

/*.btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent;
}*/

.btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-outline-primary {
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    padding: 10px;
    border: 2px solid #0d6efd;
    white-space: nowrap;
    
}

.offset-md-3 {
    margin-left: 25%;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.text-span {
    height: 36.5px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    margin-right: 0px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-text {
    position: relative;
    width: 20%;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: wrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    align-content: center;
}

.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 20%;
    min-width: 0;
    white-space: wrap;
}

.form-label {
    margin-bottom: 0.5rem;
    font: 26px system-ui;
}

.m-3 {
    margin: 1rem!important;
}

.contenedorP {
    height: 100vh;
}

.container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.imagen{
    width: 25rem;
    height: auto;
}

.cambiarTicket{
    position: absolute;
    font-size: 2.4rem ;
    top:66rem;
    left: 47.5vw;
}


.contenedorServicio{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

label{
    font-size: 2.4rem;
    width: 25rem;
    text-align: start;
}

.sBotones{
    font-size: 2.5rem;
}

.generar{
    margin-top: 1rem;
    font-size: 2.1rem;
}

.p{
    width: 35rem;
    text-align: justify;
    font-size: 1.5rem;
}

.p2{
    margin-top: 1rem;
    width: 25rem;
    text-align: center;
    font-size: 1.4rem;
}


.inputCasco{
    width: 35rem;
    font-size: 2rem;
}

.contenedorFacturacion{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
    flex-direction: column;
    font-family: 'PT Serif', serif;
}

.botonConsultarTicket{
    margin-top: 1rem;
    width: 25rem;
    font-size: 1.8rem;
    background-color: #9ce3a8;

}
.ContenedorMostrarF{
    background-color: white;
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'PT Serif', serif;
}

.contenedorDatosFactura{
    display: flex;
    flex-direction: column;
    font-family: 'PT Serif', serif;
}



.labelDatosFactura{
    width: auto;
    font-size: 2.2rem;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-weight: bold;
}

.labelDatosFacturac{
    margin-top: 0px;
    width: auto;
    font-size: 1.6rem;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-weight: bold;
}

.contendorCaculo{
    width: 100vw;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;

}

.labelDatosFacturacalculo{
    width: 40rem;
    font-size: 2rem;
    font-family: 'PT Serif', serif;
}

.selectCalcula{
    font-size: 2rem;
    width: 18rem;
}

.inputCacular{
    width: 26rem;
    font-size: 2rem;
}

.contenedorCabecera{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.ConternedorbotonesTipoCliente{
    width: 40rem;
}



.contenedorInfoF{
    display: flex;
    flex-direction: column;
    width: 40rem;

}



.imagen2{
    background-color: white;
    width: 10rem;
    height: auto;
    margin-top: 23px;
    position: relative;
    top: -37.3rem;
}

.inputF{
    width: 22rem;
    font-size: 2rem;
}

.selectF{
    font-size: 2rem;
    height: 3.4rem;
    background-color: #ece5c7;
}

.enviarF{
    font-size: 2.4rem;
}

.contenedorInfoFactura{
    display: flex;
    flex-direction: column;
    width: 35rem;
    margin-top: 1rem;
    font-weight: bold;
    font-family: 'PT Serif', serif;
    }

.contenedor-crearticket {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.contenedor-crearticket-grupo {
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-top: 1rem;
}

.contenedor-crearticket-grupo1 {
    display: flex;
    width: 50vw;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contenedor-crearticket-grupo2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.input-crearticket {
    width: 50vw;
    font-size: 2rem;
    height: 4rem;
}

.input-crearticket2 {
    width: 22vw;
    font-size: 2rem;
    height: 4rem;
}

.contenedorMensualidadPrincipal {
    display: grid;
    width: 50vw;
    width: auto;
    grid-template-columns: 25vw 25vw;
    grid-template-rows: auto;
    grid-template-areas: "contenedor1 contenedor2"
    "contenedor3 contenedor4";
}

.contenedor1 {
    grid-area: contenedor1;
}

.contenedor2 {
    grid-area: contenedor2;
}

.contenedor3 {
    grid-area: contenedor3;
}

.contenedor4 {
    grid-area: contenedor4;
}

    .alert {
     
        color: var(--bs-alert-color);
        background-color: var(--bs-alert-bg);

    }
    .alert-success {
        --bs-alert-color: #0f5132;
        --bs-alert-bg: #d1e7dd;
        --bs-alert-border-color: #badbcc;
    }

    .alert-primary {
        --bs-alert-color: #084298;
        --bs-alert-bg: #cfe2ff;
        --bs-alert-border-color: #b6d4fe;
    }
    .alert-warning {
        --bs-alert-color: #664d03;
        --bs-alert-bg: #fff3cd;
        --bs-alert-border-color: #ffecb5;
    }
    .alert-danger {
        --bs-alert-color: #842029;
        --bs-alert-bg: #f8d7da;
        --bs-alert-border-color: #f5c2c7;
    }


.marca{
   position: absolute;
    top:45rem;
    opacity:0.8;
    color:red;
    font-size: 5rem;
    transform: rotate(-45deg);
}

.ContenedorQRFactura{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

video{
    width: 50vw;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.button-Activar-Camara{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 1024px) {

    h2{
        font-size: 2.6rem;
    }
    
    label{
        font-size: 2.6rem;
    }

    
.contenedoresTicket{
    width: 48rem;
}

.p{
    width: 48rem;
}

.p2{
    width: 48rem;
}
.inputCasco{
    width: 48rem;
    font-size: 2rem;
}
    
}

