*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
}


.contenedorP{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    background-color: white
}

.seccion-Botones{
    margin-top: 1rem;
    display: flex;
    height: 10vh;
    width: 100vw;
    justify-content: center;
    flex-wrap: wrap;
}

.seccion-Botones-Boton{
    padding: 5px;
    font-size: 1.8rem;
    border: solid 2px #0d6efd;
    width: 25vw;
    text-align: center;
}



.lista{
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
    display: flex;
    padding: 10px;
  justify-content: center;
}

.contenedorLista{

  margin-top: 1rem;
}


.contenedorLista label{
    width: auto;
    font-size: 2rem;
}


.ContenedorTicketE{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'PT Serif', serif;
    justify-content: center;
    background-color: white;
}



.col-md-3 input{
    margin-left: 1rem;
}

.col-md-3 label{
    width: auto;
}

.ContenedorPago{
    width: 22vw;
    display: flex;
    align-items: start;
}


#contenedorDias input{
    margin-left: 0px;
    font-size: 1.8rem;
}




#contendoresSpan span{
    font-size: 2rem;
}

.cambiarOtro{
    position: relative;
    top:35rem;
    left: 30rem;
    font-size: 2rem;
}

.justify-content-md-center {
    justify-content: center!important;
}

.mt-3 {
    margin-top: 1rem!important;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.mb-3 {
    margin-bottom: 1rem!important;
}

.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}

.offset-md-3 {
    margin-left: 25%;
}

.offset-md-4 {
    margin-left: 0%;
}

.offset-md-5 {
    margin-left: 10%;
    margin-top: 1rem;
}

.mt-5 {
    margin-top: 1rem;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    width: 20%;
    align-self: center;
    margin: 31%;
}

.gap-2 {
    gap: 0.5rem!important;
}

.d-grid {
    display: grid!important;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.btn-verde {
    width: 40vw;
    color: #fff;
    background-color: #146c43;
    padding: 1rem;
    border-color: #146c43;
    border-radius: 10px;
}

.btn-verde:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}




@media (max-width: 1024px) {



}

