.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-header {
  padding: 2px 16px;
  background-color: #fefefe;
  color: black;
}

.modal-body {
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #fefefe;
  display: flex;
  justify-content: space-between;
}


.contenedor{
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}


.contenedorGrupo{
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.elementLabel{
  font-size: 18px;
  margin-bottom: 2px;
}

.elemetInputCaj{
  font-size: 14px;
  padding: 5px;
  width: 15vw;
  border: 0.5px solid black;
  border-radius: 5px;
}

.elemetInputCaj2{
  font-size: 14px;
  padding: 5px;
  width: 30vw;
  border: 0.5px solid black;
  border-radius: 5px;
}

.danger{
    background-color: #f8d7da;
    color: #842029;
    border: solid 1px #f5c2c7;
}

.agregarBotonCaj{
  background-color: #D1E7DD;
  color: #0A3622;
  border: solid 1px #a3cfbb;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}

.importarBotonCaj{
  background-color: #CFE2FF;
  color: #052C65;
  border: solid 1px #9ec5fe;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}

.exportBotonCaj{
  background-color: #FFF3CD;
  color: #664D03;
  border: solid 1px #ffe69c;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}


