*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    font-size: 62.5%;
}

.contendorConsultarFactura{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.span{
    font-size: 2rem;
}

.input{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    font-size: 2rem;
}

.boton{
    margin-top: 1rem;
    font-size: 2rem;
    background-color: #9ce3a8;
    border: none;
    padding: 0.5rem;
}